// define a mixin object
import { AuthenticatedDictionaryEntryService } from '../services/authenticatedDictionaryEntryService'

export const dictionaryEntryMixin = {
  methods: {
    toggleInfo () {
      this.info = !this.info ? this.$t('1. special case is everything that is no person description e.g. \'Schülerzeitung\' or \'herrlich\'') : ''
      this.info2 = !this.info2 ? this.$t('2. please enter words without article & capitalize nouns') : ''
      this.info3 = !this.info3 ? this.$t('3. gender neutral terms are not mandatory, you can e.g. create a comment for a specific word') : ''
      this.info4 = !this.info4 ? this.$t('4. if words are neutral in plural, just leave these fields empty') : ''
      this.info5 = !this.info5 ? this.$t('5. Whatever shortform placeholder you are using. add entries using the gender star') : ''
      this.info6 = !this.info6 ? this.$t('6. If you have more questions, ask them on our Discord: https://discord.gg/q359tAS') : ''
    },
    toggleOptions () {
      this.showSettings = !this.showSettings
    },
    containsSearchTerm (candidates) {
      const searchTerm = (typeof (this.searchTerm) === 'string' ? this.searchTerm : '') || this.searchTerm.code.c || ''
      return candidates.includes(searchTerm) && searchTerm
    },
    muteCard (data) {
      return (data.type !== 'neut')
    },
    like (_id) {
      // implementation of the same code that runs in the backend.
      // this way we can avoid to re-query the backend after vote. result: no reshuffling. profit.
      // backend
      return new Promise(resolve => {
        // console.log(_id)
        AuthenticatedDictionaryEntryService.like(_id).then(response => {
          // frontend
          if (!this.loadedDictionaryEntries.length) return resolve('liked in backend only')
          const clickedElement = this.loadedDictionaryEntries.find(element => element.id === _id)
          if (clickedElement.like_by_me) {
            clickedElement.total_likes--
            clickedElement.like_by_me = false
          } else {
            if (clickedElement.dislike_by_me) {
              clickedElement.total_dislikes--
              clickedElement.dislike_by_me = false
            }
            clickedElement.total_likes++
            clickedElement.like_by_me = true
          }
          return resolve('liked in front & backend')
        })
      })
    },
    dislike (_id) {
      // backend
      AuthenticatedDictionaryEntryService.dislike(_id)
      // frontend
      const clickedElement = this.loadedDictionaryEntries.find(element => element.id === _id)
      if (clickedElement.dislike_by_me) {
        clickedElement.total_dislikes--
        clickedElement.dislike_by_me = false
      } else {
        if (clickedElement.like_by_me) {
          clickedElement.total_likes--
          clickedElement.like_by_me = false
        }
        clickedElement.total_dislikes++
        clickedElement.dislike_by_me = true
      }
    },
    flag (_id) {
      // backend
      AuthenticatedDictionaryEntryService.flag(_id)
      // frontend
      const clickedElement = this.loadedDictionaryEntries.find(element => element.id === _id)
      clickedElement.flag_by_me = !clickedElement.flag_by_me
    },
    add (_id) {
      this.$router.push({ path: '/dictionary_entry/add/?uid=' + _id })
    }
  }
}
