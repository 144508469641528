// define a mixin object

import { DictionaryEntryService } from '../services/dictionaryEntryService'
export const listMixin = {
  data () {
    return {
      loadedDictionaryEntries: [],
      disableLoadingNextPage: false,
      searchTerm: '', // Hint: When auto completion list is open, the search term is in searchterm.code.c
      nextPageApi: '',
      page: 1,
      pageSize: 9999,
      autocompletionOptions: [],
      isPaginated: false,
      isApiCalled: false
    }
  },
  methods: {
    prepareAutoCompleteOptions (_data) {
    // returns list to be set eg. by fetchAutoCompletionOptions
      const dList = []
      if (_data.singular_masculine !== '') {
        dList.push({
          label: _data.singular_gender_neutral,
          code: { c: _data.singular_masculine, id: Math.random().toString(36).substr(2, 9) }
        })
      }
      if (_data.singular_feminine !== '') {
        dList.push({
          label: _data.singular_gender_neutral,
          code: { c: _data.singular_feminine, id: Math.random().toString(36).substr(2, 9) }
        })
      }

      if (_data.term !== '') {
        dList.push({
          label: _data.alternative,
          code: { c: _data.term, id: Math.random().toString(36).substr(2, 9) }
        })
      }

      if (_data.plural_masculine !== '') {
        dList.push({
          label: _data.plural_gender_neutral,
          code: { c: _data.plural_masculine, id: Math.random().toString(36).substr(2, 9) }
        })
        if (_data.plural_feminine !== '') {
          dList.push({
            label: _data.plural_gender_neutral,
            code: { c: _data.plural_feminine, id: Math.random().toString(36).substr(2, 9) }
          })
        }
      }
      return dList
    },
    fetchAndSetFirstPage (searchTerm, userFilter, ordering) {
    // old name: getAllTerms
    // arguments are passed via URL and prop 'filterBy'
    // please use newSearchTerm interface if at all possible
      const instance = this
      // set filtered cards according to search query. When search query is empty, use 'trending-sort'
      return DictionaryEntryService.getListViewData(searchTerm.trim(), userFilter, ordering)
        .then((response) => {
          this.isApiCalled = true
          instance.loadedDictionaryEntries = response.data.results
          instance.pageSize = response.data.total_page
          instance.nextPageApi = response.data.next
          instance.disableLoadingNextPage = !response.data.results
          const hasNeutralEntry = response.data.results.map(r => r.type).reduce((accumulator, element) => {
            return accumulator || element === 'neut'
          }, false)
          instance.neutralEntryInfo = ''
          instance.callToAction = ''
          if (!hasNeutralEntry && response.data.results.length > 0) {
            instance.neutralEntryInfo = instance.$t('No gender neutral entry yet')
            instance.callToAction = instance.$t('The community would be grateful if you would create an entry')
          }
          if (!hasNeutralEntry && response.data.results.length === 0) {
            instance.neutralEntryInfo = instance.$t('No entry yet')
            instance.callToAction = instance.$t('The community would be grateful if you would create a new entry')
          }
        })
    },
    setSearchTerm (term) {
      return new Promise(resolve => {
        this.searchTerm = {}
        this.searchTerm.code = {}
        this.searchTerm.code.c = term
        resolve('SeachTerm set')
      })
        .catch((error) => {
          console.log(error)
        })
    },
    setUrl (newSearchTerm, validated = false) {
      const instance = this
      return new Promise(resolve => {
        // console.log(newSearchTerm)
        if (!newSearchTerm) {
          resolve('URL empty')
        } else {
        // if (!this.$route.query.search) {
          const dynamicURL = encodeURI('/?search=' + newSearchTerm)
          const staticURL = encodeURI('/dict/' + newSearchTerm)
          const url = validated ? staticURL : dynamicURL
          // TODO: for future me find out why the NavigationDuplicated error appears without the catch.
          // eslint-disable-next-line handle-callback-err
          if (![dynamicURL, staticURL].includes(instance.$route.path)) instance.$router.push(url).catch(err => {})
          resolve('URL set')
        }
      })
        .catch((error) => {
          console.log(error)
        })
    },
    updateUrl (newSearchTerm) {
      const instance = this
      return new Promise(resolve => {
        // console.log(newSearchTerm)
        if (!newSearchTerm) {
          resolve('URL empty')
        } else {
          if (!this.loadedDictionaryEntries.length) {
            resolve('content not yet loaded')
          }
          // if (!this.$route.query.search) {
          const staticURL = encodeURI('/dict/' + newSearchTerm)
          // TODO: for future me Find out why the NavigationDuplicated error appears without the catch.
          if ([this.loadedDictionaryEntries[0].term,
            this.loadedDictionaryEntries[0].singular_masculine,
            this.loadedDictionaryEntries[0].singular_feminine,
            this.loadedDictionaryEntries[0].plural_masculine,
            this.loadedDictionaryEntries[0].plural_feminine].includes(newSearchTerm)) {
          // eslint-disable-next-line handle-callback-err
            instance.$router.replace({ path: `${staticURL}` }).catch(err => {})
          }
          resolve('URL set')
        }
      })
        .catch((error) => {
          console.log(error)
        })
    },
    scrollUp () {
      return new Promise(resolve => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        resolve('scroled up')
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
