import { BaseService } from './base.service'
import { Http } from './http.init'

export class AuthenticatedDictionaryEntryService extends BaseService {
  static get entity () {
    return 'authenticated_dictionary_entry'
  }

  static request (status = { auth: true }) {
    return new Http(status)
  }
}
