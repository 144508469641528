import { BaseService } from './base.service'
import { Http } from './http.init'
import { API_URL } from '../env'
import axios from 'axios'
import { ErrorWrapper, ResponseWrapper } from './utils'
var Vue = require('vue')

export class DictionaryEntryService extends BaseService {
  static get entity () {
    return 'dictionary_entry_list'
  }

  static request (status = { auth: true }) {
    return new Http(status)
  }

  static fixRunningText (runningText, wordTypesToAnalyse, expectedReturnElements) {
    const url = `${API_URL}/dictionary_entry/fix_running_text/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.post(url, { runningText, wordTypesToAnalyse, expectedReturnElements }, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static parseWebpage (urlToParse) {
    const url = `${API_URL}/dictionary_entry/parse_webpage/`
    const headersConfig = this.request().request
    return new Promise((resolve, reject) => {
      axios.post(url, { urlToParse }, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  static getAutoCompletionData (search) {
    const url = `${API_URL}/api/${this.entity}/?user=all&search=${search}&limit=unlimited&order=top`
    const authToken = Vue.default.cookie.get('token')
    let headersConfig = ''
    if (authToken) {
      headersConfig = this.request().request
    }
    return new Promise((resolve, reject) => {
      axios.get(url, headersConfig)
        .then(response => {
          return resolve(new ResponseWrapper(response, response.data, ''))
        }).catch(error => {
          reject(new ErrorWrapper(error))
        })
    })
  }
}
